


































import Vue from 'vue'
import TimePickTable from '@/components/TimePickTable.vue'
import ProgressTracker from '@/components/ProgressTracker.vue'
import { AvailableCarInfo } from 'fido-entity/lib'
import ApiReserve from '@/api/methods/Reservation'
import dayjs from 'dayjs';
import { commonModule } from '@/store/CommonModule'

type DataType = {
  reservation: { startDate: string, endDate: string},
  cars: AvailableCarInfo[]
};

type StatusListItem = {
  date: string,
  status: boolean
};

const RESERVATION_DATE_RANGE = 3;

export default Vue.extend({
  components: { TimePickTable, ProgressTracker },
  
  name: 'PickTime',

  data: (): DataType => {
    return {
      reservation: {
        startDate: '',
        endDate: '',
      },
      cars: []
    }
  },

  props: {
    date: {
      type: String,
      default: ''
    }
  },

  methods: {
    clearInput(): void {
      this.reservation.startDate = '';
      this.reservation.endDate = '';
    },
    toConfirmPolicy(): void {
      this.$router.push({name: 'ConfirmPolicy', params: {
        startDate: this.reservation.startDate,
        endDate: this.reservation.endDate,
        carId: this.cars[0].id
      }});
    }
  },

  computed: {
    statusList(): StatusListItem[] {
      if (!this.cars.length || !this.date) {
        return [];
      }

      let baseDate
      if (dayjs(this.date).isBefore(dayjs().add(1, 'day'))) {
        baseDate = dayjs(dayjs().add(1, 'hour').add(1, 'day').format('YYYY-MM-DDTHH:00:00'));
      } else {
        baseDate = dayjs(dayjs(this.date).format('YYYY-MM-DDTHH:00:00'));
      }
      

      let list: StatusListItem[] = [];

      for (let i = 0; i < 24 * RESERVATION_DATE_RANGE; ++i) {
        const date = baseDate.add(i, 'h').toISOString();

        if (!dayjs().add(1, 'h').isBefore(date, 'hour')) continue;

        const status = this.cars[0].availableTimes.includes(date);
        list.push({date, status});
      }

      return list;
    },
    isFilled(): boolean {
      return this.reservation.startDate !== '' && this.reservation.endDate !== '';
    },
    isProperEndTime(): boolean {
      if (this.reservation.endDate) {
        const hour = dayjs(this.reservation.endDate).hour();
        return hour <= 22 && hour >= 10;
      } else {
        return false;
      }
    }
  },

  async created() {
    if (this.date) {
      commonModule.setLoader(true);
      const input = {
        startedAt: this.date,
        endedAt: dayjs(this.date).add(RESERVATION_DATE_RANGE, 'day').format('YYYY-MM-DD')
      }
      await ApiReserve.getAvailableTimes(input).then((res) => {
        this.cars = res.cars;
      })
      commonModule.setLoader(false);
    } else {
      this.$router.push({ name: 'PickDate' });
    }
  }
})
