












































































import Vue from 'vue';
import LoaderContainer from '@/components/LoaderContainer.vue';

export default Vue.extend({
  name: 'App',

  components: { LoaderContainer },

  data: () => ({
    drawer: false,
    routePath: '',
    isAuthed: false,
    pageTitle: '',
  }),

  computed: {
    isSignIn(): boolean {
      return this.$route.path.match('/signin') !== null;
    },
    hideAppBar(): boolean {
      return this.$route.path === '/no-subscription';
    },
    pageName(): string {
      const pathname = this.$route.path;
      if (pathname.match('/home')) {
        return 'ホーム';
      } else if (pathname.match('/reservation/.*')){
        if (pathname.match('/reservation/history')) {
          return '予約履歴';
        } else if (pathname.match('/reservation/cancel-.*')) {
          return '予約キャンセル'
        } else if (pathname.match('/reservation/detail')) {
          return '予約詳細'
        } else {
          return '新規予約'
        }
      } else if (pathname.match('/buy-point')){
        return 'ポイント購入'
      } else if (pathname.match('/scratch/')){
        return '破損報告'
      } else if (pathname.match('/user-setting.*')){
        return 'アカウント設定'
      }
      return '';
    }
  },
});
