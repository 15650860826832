




































import Vue from 'vue'
import PeriodExpresser from '@/components/PeriodExpresser.vue'
import dayjs from 'dayjs'
import ApiReservation from '@/api/methods/Reservation'
import { ReservationInfo } from 'fido-entity/lib'
import { commonModule } from '@/store/CommonModule'


type DataType = {
  // reservations: ReservationInfo[]
  reservation: ReservationInfo | null
}

export default Vue.extend({
  components: { PeriodExpresser },
  name: 'LatestReservation',

  data: (): DataType => {
    return {
      reservation: null
    }
  },

  props: {
    reservationId: {
      type: String,
      default: ''
    }
  },

  methods: {
    toCancelReservation(): void {
      if (this.reservation){
        this.$router.push({name: 'CancelReservation', params: {
          reservationId: this.reservation.id
        }})
      }
    },
    toImageUpload(): void {
      if (this.reservation){
        this.$router.push({name: 'ImageUpload', params: {
          reservationId: this.reservation.id
        }})
      }
    }
  },

  async created() {
    if (this.reservationId) {
      commonModule.setLoader(true);
      const data = await ApiReservation.getReserve(this.reservationId);
      this.reservation = data.reservation;
      commonModule.setLoader(false);
    } else {
      this.$router.push({ name: "Home" });
    }
  },

  computed: {
    startDate(): string {
      return this.reservation ? this.reservation.startedAt : '';
    },
    endDate(): string {
      return this.reservation ? this.reservation.endedAt : '';
    },
    createdDate(): string {
      return this.reservation ? dayjs(this.reservation.createdAt).format('YYYY年M月D日 H:mm') : '不正な日付';
    },
    isComing(): boolean {
      if (!this.reservation) return false;

      return dayjs().isBefore(this.reservation.startedAt);
    }
    // sampleData(): Reservation {
    //   return {
    //     reservationId: '',
    //     start: dayjs().format(),
    //     end: dayjs().add(2, 'hour').format()
    //   }
    // }
  }
  
})
