












import Vue from 'vue'
import { commonModule } from '@/store/CommonModule';
export default Vue.extend({
  name: 'LoaderContainer',

  computed: {
    isLoading() {
      return commonModule.isLoading;
    }
  }
})
