
























import ApiPayment from '@/api/methods/Payment';
import { commonModule } from '@/store/CommonModule';
import Vue from 'vue'
export default Vue.extend({
  methods: {
    async gotoSubscription() {
      commonModule.setLoader(true);
      const res = await ApiPayment.postSubscription();

      const stripeKey = process.env.VUE_APP_STRIPE_PK || '';
      const stripe = Stripe(stripeKey) as stripe.Stripe;
      if (res.sessionId && stripe) {
        stripe.redirectToCheckout({
          sessionId: res.sessionId,
        });
      }
      commonModule.setLoader(false);
    }
  }
});
