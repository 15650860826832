








































import Vue from 'vue'
import ProgressTracker from '@/components/ProgressTracker.vue';

export default Vue.extend({
  components: { ProgressTracker },

  name: 'ConfirmCheckbox',

  data: () => {
    return {
      checkLabel:[
        "返却時間を予め理解し、利用します。",
        "会員本人が使用します。（転貸の禁止）",
        "有効期限内の運転免許証を携帯しています。",
        "運転前に飲酒・眠気を催す薬の内服等はしません。",
        "禁止事項（飲食・飲酒、ペット乗車、公序良俗に反する行為、危険運転等の違反行為）について理解をし、遵守します。",
        "事故発生時の対応手順、緊急連絡先を確認しました。"
      ],
      checkStatus: []
    }
  },

  props: {
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    carId: {
      type: String,
      default: ''
    }
  },

  methods: {
    toConfirmReservation(): void {
      this.$router.push({name: 'ConfirmReservation', params: {
        startDate: this.startDate,
        endDate: this.endDate,
        carId: this.carId
      }});
    }
  },

  created() {
    if (!this.startDate || !this.endDate || !this.carId) {
      this.$router.push({ name: 'PickDate' });
    }
  }
  
})
