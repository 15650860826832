
























































import Vue from 'vue';
import ApiResource from '@/api/methods/Resource';
import ApiScratch from '@/api/methods/Scratch';
import Swal from 'sweetalert2';
import { fidoApi } from '@/api/common';
import { commonModule } from '@/store/CommonModule';

type DataType = {
  images: string[],
  files: File[]
  shownId: number,
  isLoading: boolean
}

export default Vue.extend({
  name: 'ImageUpload',

  data: ():DataType => {
    return {
      images: [],
      files: [],
      shownId: 0,
      isLoading: false
    }
  },

  props: {
    reservationId: {
      type: String,
      default: ''
    }
  },

  methods: {
    showDialog() {
      const input = this.$refs.input as HTMLInputElement;
      input.click();
    },
    async addImage() {
      const input = this.$refs.input as HTMLInputElement;
      const files = input.files;
      if(files && files[0]) {
        this.files.push(files[0]);
        this.isLoading = true;
        await this.getImageData(files[0])
          .then((data) => {
            if (typeof data === 'string') {
              this.images.push(data);
            }
            this.isLoading = false;
          }).catch(() => {
            Swal.fire('読み込みエラー', `画像の読み込みに失敗しました`, 'error');
          })
      }
    },
    getImageData(file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {resolve(reader.result)};
        reader.onerror = error => reject(error);
      });
    },
    uploadErrorAlert() {
      Swal.fire('アップロードエラー', `画像のアップロードに失敗しました`, 'error');
    },
    getUploadPromise() {
      return this.files.map(async (f) => {
        const input = {
          imageCategory: 'scratch',
          reservationId: this.reservationId,
          filename: f.name
        }
        const res = await ApiResource.fetchResourceUrl(input);

        if(!res || !res.url) throw Error('failed to get resource url');

        const putUrl = res.url;
        await fidoApi.putImage(putUrl, f, f.type);

        const imageUrl = new URL(putUrl);
        return imageUrl.origin + imageUrl.pathname;
      })
    },
    async uploadImages() {
      commonModule.setLoader(true);
      await Promise.all(this.getUploadPromise())
        .then(async (imageUrls) => {
          await ApiScratch.createScratch({
            reservationId: this.reservationId,
            imageUrls
          }).then(() => {
            this.$router.push({ name: 'UploadComplete' })
          });
        })
        .catch((error) => {
          this.uploadErrorAlert();
          console.log(error);
        })
      commonModule.setLoader(false);
    }
  },

  created() {
    if (!this.reservationId) {
      this.$router.push({name: 'ReservationHistory'});
    }
  }

})
