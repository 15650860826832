import {
  Module,
  Mutation,
  Action,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import AuthApi from "@/api/methods/Auth";
import store from "@/store";
import { AuthSigninRequestBody } from "fido-entity/lib";

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  hasAuthorized: boolean;
}

@Module({
  dynamic: true,
  store,
  name: "AuthModule",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") !== null,
})
class AuthModule extends VuexModule implements AuthState {
  public accessToken: string | null = null;
  public refreshToken: string | null = null;
  public hasAuthorized = false;

  @Mutation
  public setHasAuthorized(authorized: boolean) {
    this.hasAuthorized = authorized;
  }

  @Mutation
  public setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  @Mutation
  public setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  @Mutation
  public clearTokens() {
    this.accessToken = null;
    this.refreshToken = null;
  }

  @Action({ rawError: true })
  public async checkAuth() {
    if (this.accessToken && this.refreshToken) {
      const input = {
        accessToken: this.accessToken,
        refreshToken: this.refreshToken,
      };
      const res = await AuthApi.checkAuth(input);

      if (res.accessToken && res.refreshToken) {
        this.setAccessToken(res.accessToken);
        this.setRefreshToken(res.refreshToken);
        this.setHasAuthorized(true);
        return true;
      }
    }

    this.unAuthorize();
    return false;
  }

  @Action({ rawError: true })
  public async authorize(input: AuthSigninRequestBody) {
    const res = await AuthApi.signIn(input);
    if (res.session && res.session.accessToken && res.session.refreshToken) {
      this.setAccessToken(res.session.accessToken);
      this.setRefreshToken(res.session.refreshToken);
      this.setHasAuthorized(true);

      return res.userInfo;
    }

    this.setHasAuthorized(false);
    throw Error("Session is null.");
  }

  @Action({ rawError: true })
  public unAuthorize() {
    this.clearTokens();
    this.setHasAuthorized(false);
  }
}

export const authModule = getModule(AuthModule);
