import {
  CreateResourceUrlRequestBody,
  ResourceResponse,
} from "fido-entity/lib";
import { fidoApi } from "../common";

export default class ApiResource {
  public static async fetchResourceUrl(
    input: CreateResourceUrlRequestBody
  ): Promise<ResourceResponse> {
    const res = await fidoApi.post("/resource", input);

    if (res) {
      return res as ResourceResponse;
    }

    throw Error("Response is null / undefined");
  }
}
