












































import Vue from 'vue'
import { ReservationArrayResponse, ReservationInfo } from 'fido-entity/lib'
import ApiReservation from '@/api/methods/Reservation'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import ProceedingInfo from '@/components/ProceedingInfo.vue'
import PointInfo from '@/components/PointInfo.vue'
import PeriodExpresser from '@/components/PeriodExpresser.vue'
import { commonModule } from '@/store/CommonModule'
import ApiUser from '@/api/methods/User'

type DataType = ReservationArrayResponse;

dayjs.extend(isBetween);

export default Vue.extend({
  components: { ProceedingInfo, PointInfo, PeriodExpresser },
  name: 'Home',

  data: (): DataType => {
    return {
      point: 0,
      reservations: []
    }
  },

  async created() {
    commonModule.setLoader(true);
    const user = await ApiUser.getUser();
    if (user && !user.isSubscribed) {
      commonModule.setLoader(false);
      this.$router.push({name: 'NoSubscription'});
      return;
    }
    const data = await ApiReservation.getReserveList();
    this.point = data.point;
    this.reservations = data.reservations.sort((a, b) => {
      const dateA = dayjs(a.startedAt);
      if (dateA.isAfter(b.startedAt)) return 1;
      else if (dateA.isBefore(b.startedAt)) return -1;
      else return 0;
    });
    commonModule.setLoader(false);
  },

  methods: {
    onReservationClicked(id: string) {
      this.$router.push({name: 'ReservationDetail', params: { reservationId: id }})
    }
  },

  computed: {
    nearestReservation(): ReservationInfo | null {
      if (!this.reservations.length) {
        return null;
      }

      return this.reservations
        .filter((r) => !r.isFinished)
        .reduce((prev, current) => {
          const prevStart = dayjs(prev.startedAt);
          const currentStart = dayjs(current.startedAt);
          if (currentStart.isBefore(prevStart)) {
            return current;
          } else {
            return prev;
          }
        })
    },
    isProceeding(): boolean {
      const nearest = this.nearestReservation;
      if (nearest) {
        return dayjs().isBetween(nearest.startedAt, nearest.endedAt);
      } else {
        return false;
      }
    },
    comingReservations(): ReservationInfo[] {
      return this.reservations.filter((item) => {
        return dayjs().isBefore(item.startedAt);
      })
    }
  }

})
