























import Vue from 'vue'
export default Vue.extend({
  name: 'CancelComplete',

  props: {
    point: {
      type: String,
      default: ''
    }
  },

  created() {
    if (!this.point) this.$router.push({ name: 'Home' });
  }
  
})
