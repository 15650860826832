import {
  ScratchArrayResponse,
  CreateScratchInfoRequestBody,
  ScratchResponse,
} from "fido-entity/lib";
import { fidoApi } from "../common";

export default class ApiScratch {
  public static async getScratchList(
    reservationId: string
  ): Promise<ScratchArrayResponse> {
    const res = await fidoApi.get("/scratch", { reservationId });

    if (res) {
      return res as ScratchArrayResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async createScratch(
    input: CreateScratchInfoRequestBody
  ): Promise<ScratchResponse> {
    const res = await fidoApi.post("/scratch", input);

    if (res) {
      return res as ScratchResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async getScratch(scratchId: string): Promise<ScratchResponse> {
    const res = await fidoApi.get(`/scratch/${scratchId}`, null);

    if (res) {
      return res as ScratchResponse;
    }

    throw Error("Response is null / undefined");
  }
}
