

















import ApiReserve from '@/api/methods/Reservation';
import dayjs from 'dayjs';
import { ReservationInfo } from 'fido-entity/lib'
import PeriodExpresser from '@/components/PeriodExpresser.vue';
import Vue from 'vue'
import { commonModule } from '@/store/CommonModule';
type DataType = {
  reservations: ReservationInfo[]
};
export default Vue.extend({
  name: 'ReservationHistory',

  components: { PeriodExpresser },

  data: ():DataType => {
    return {
      reservations: []
    }
  },

  methods: {
    onReservationClicked(id: string) {
      this.$router.push({name: 'ReservationDetail', params: { reservationId: id }})
    }
  },

  async created() {
    commonModule.setLoader(true);
    const data = await ApiReserve.getReserveList();
    this.reservations = data.reservations.filter((item) => {
      return dayjs().isAfter(item.endedAt);
    }).sort((a, b) => {
      const dateA = dayjs(a.startedAt);
      if (dateA.isAfter(b.startedAt)) return -1;
      else if (dateA.isBefore(b.startedAt)) return 1;
      else return 0;
    });
    commonModule.setLoader(false);
  }
})
