

























import Vue from 'vue'
export default Vue.extend({
  name: 'ProgressTracker',

  props: {
    steps: {
      type: Number,
      default: 4
    },
    current: {
      type: Number,
      default: 1
    }
  },

  computed: {
    stepClasses(): { key: number, class: string }[] {
      let items = [];
      for (let i = 0; i < this.current - 1; ++i) {
        items.push({key: i, class: 'done'});
      }
      items.push({key: this.current - 1, class:'current'});
      for (let i = this.current; i < this.steps; ++i) {
        items.push({key: i, class:''});
      }
      return items;
    }
  }
})
