
























import Vue from 'vue'
import ProgressCircle from '@/components/ProgressCircle.vue'
import dayjs from 'dayjs'
export default Vue.extend({
  name: 'ProceedingInfo',

  components: {
    ProgressCircle
  },

  props:
  {
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
  },
  
  computed: {
    endDay(): string {
      return dayjs(this.endDate).format('M/DD');
    },
    endTime(): string {
      return dayjs(this.endDate).format('HH:mm')
    }
  }
  
})
