import {
  UserInfoResponse,
  CryptoUtils,
  OperationSuccessResponse,
  UpdateUserPasswordRequestBody,
} from "fido-entity/lib";
import { fidoApi } from "../common";

const aesKey = process.env.VUE_APP_AES_KEY;
const aesSalt = process.env.VUE_APP_AES_SALT;

export default class ApiUser {
  public static async getUser(): Promise<UserInfoResponse> {
    const res = await fidoApi.get("/user", null);

    if (res) {
      return res as UserInfoResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async updateEmail(email: string): Promise<UserInfoResponse> {
    const res = await fidoApi.put("/user/email", { email });

    if (res) {
      return res as UserInfoResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async updatePassword(
    input: UpdateUserPasswordRequestBody
  ): Promise<OperationSuccessResponse> {
    // ここで暗号化を行う
    const cu = new CryptoUtils(aesKey, aesSalt);
    const previousPassword = cu.encryptAes(input.previousPassword);
    const proposedPassword = cu.encryptAes(input.proposedPassword);

    const newInput = {
      previousPassword,
      proposedPassword,
    };

    const res = await fidoApi.put("/user/password", newInput);

    if (res) {
      return res as OperationSuccessResponse;
    }

    throw Error("Response is null / undefined");
  }
}
