import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import PointIcon from "@/assets/point.vue";

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: "mdi",
  icons: {
    values: {
      point: {
        component: PointIcon,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#6D6C6F",
        secondary: "#D4D4D4",
        accent: "#EEDB15",
        error: "#b71c1c",
      },
    },
  },
});
