import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import SignIn from "../views/SignIn.vue";
import PickDate from "../views/Reservation/PickDate.vue";
import PickTime from "../views/Reservation/PickTime.vue";
import ConfirmPolicy from "@/views/Reservation/ConfirmPolicy.vue";
import ConfirmReservation from "@/views/Reservation/ConfirmReservation.vue";
import ReservationComplete from "@/views/Reservation/ReservationComplete.vue";
import CancelReservation from "@/views/Reservation/CancelReservation.vue";
import CancelComplete from "@/views/Reservation/CancelComplete.vue";
import BuyPoint from "@/views/BuyPoint.vue";
import ImageUpload from "@/views/Scratch/ImageUpload.vue";
import UploadComplete from "@/views/Scratch/UploadComplete.vue";
import UserSetting from "@/views/UserSetting/UserSetting.vue";
import ChangePassword from "@/views/UserSetting/ChangePassword.vue";
import ReservationDetail from "@/views/Reservation/ReservationDetail.vue";
import ReservationHistory from "@/views/Reservation/ReservationHistory.vue";
import NoSubscription from "@/views/NoSubscription.vue";
import ConfirmCheckbox from "@/views/Reservation/ConfirmCheckbox.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: {
      path: "/signin",
    },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/reservation/pick-date",
    name: "PickDate",
    component: PickDate,
  },
  {
    path: "/reservation/pick-time",
    name: "PickTime",
    component: PickTime,
    props: true,
  },
  {
    path: "/reservation/confirm-policy",
    name: "ConfirmPolicy",
    component: ConfirmPolicy,
    props: true,
  },
  {
    path: "/reservation/confirm-reservation",
    name: "ConfirmReservation",
    component: ConfirmReservation,
    props: true,
  },
  {
    path: "/reservation/confirm-checkbox",
    name: "ConfirmCheckbox",
    component: ConfirmCheckbox,
    props: true,
  },
  {
    path: "/reservation/reservation-complete",
    name: "ReservationComplete",
    component: ReservationComplete,
    props: true,
  },
  {
    path: "/reservation/detail",
    name: "ReservationDetail",
    component: ReservationDetail,
    props: true,
  },
  {
    path: "/reservation/history",
    name: "ReservationHistory",
    component: ReservationHistory,
  },
  {
    path: "/reservation/cancel-reservation",
    name: "CancelReservation",
    component: CancelReservation,
    props: true,
  },
  {
    path: "/reservation/cancel-complete",
    name: "CancelComplete",
    component: CancelComplete,
    props: true,
  },
  {
    path: "/buy-point",
    name: "BuyPoint",
    component: BuyPoint,
  },
  {
    path: "/scratch/image-upload",
    name: "ImageUpload",
    component: ImageUpload,
    props: true,
  },
  {
    path: "/scratch/upload-complete",
    name: "UploadComplete",
    component: UploadComplete,
  },
  {
    path: "/user-setting",
    name: "UserSetting",
    component: UserSetting,
  },
  {
    path: "/user-setting/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/no-subscription",
    name: "NoSubscription",
    component: NoSubscription,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
