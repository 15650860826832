











































import TimePickCell from '@/components/TimePickCell.vue'
import dayjs from 'dayjs'
import Vue, { PropType } from 'vue';

type DataType = {
  startId?: number,
  endId?: number,
};

type StatusListItem = {
  date: string,
  status: boolean
};

export default Vue.extend({
  components: { TimePickCell },

  name: 'TimePickTable',

  data: (): DataType => {
    return {
      startId: undefined,
      endId: undefined,
    }
  },

  props: {
    statusList: {
      type: Array as PropType<StatusListItem[]>,
      default: () => []
    },
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    }
  },
  watch: {
    startId: function (val) {
      this.$emit('update:startDate', val !== undefined ? this.statusList[val].date : '');
    },
    endId: function (val) {
      this.$emit(
        'update:endDate',
        val !== undefined
          ? dayjs(this.statusList[val].date).add(1, 'h').toISOString()
          : ''
      )
    }
  },
  methods: {
    handleSelection(id: number): void {
      if (this.isDisabled(id)) return;

      if (this.startId === undefined) {
        this.startId = id;
        this.endId = id + 1;
      } else {
        if (id === this.startId) return;
        this.endId = id;
      }
    },
    reachableId(id: number): number {
      let res = id;
      while (res < this.statusList.length && this.statusList[res].status) {
        ++res;
      }
      return res;
    },
    isHighlighted(id: number): boolean {
      if (this.startId !== undefined) {
        if (this.endId !== undefined) {
          return id >= this.startId && id <= this.endId;
        } else {
          return id === this.startId;
        }
      } else {
        return false;
      }
    },
    isDisabled(id: number): boolean {
      if (this.statusList[id].status) {
        if (this.startId !== undefined) {
          const reachable = this.reachableId(this.startId);
          return !(id >= this.startId && id <= reachable);
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isOutlined(id: number): boolean {
      console.log(id === this.startId || id === this.endId);
      return id === this.startId || id === this.endId;
    },
    clearInput(): void {
      this.startId = undefined;
      this.endId = undefined;
    }
  },

  computed: {
    startDay(): string {
      if (this.startDate) {
        return dayjs(this.startDate).format('M/D');
      } else {
        return '';
      }
    },
    startTime(): string {
      if (this.startDate) {
        return dayjs(this.startDate).format('HH:mm');
      } else {
        return '';
      }
    },
    endDay(): string {
      if (this.endDate) {
        return dayjs(this.endDate).format('M/D');
      } else {
        return '';
      }
    },
    endTime(): string {
      if (this.endDate) {
        return dayjs(this.endDate).format('HH:mm');
      } else {
        return '';
      }
    }
  }

})
