













































import { commonModule } from '@/store/CommonModule';
import Vue from 'vue';

import ApiPoint from '@/api/methods/Point';
import ApiUser from '@/api/methods/User';
import { UserInfo } from 'fido-entity/lib';

interface DataType {
  currentPoints: number,
  newPoints?: number
  user?: UserInfo;
}

export default Vue.extend({
  name: 'BuyPoint',

  data: (): DataType => {
    return {
      currentPoints: 0,
      newPoints: undefined,
      user: undefined
    }
  },
  computed: {
    estimatedPrice(): string {
      const price = this.newPoints === undefined ? 0 : this.newPoints * this.pointPriceNumber;
      return price.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,');
    },
    pointPrice(): string {
      if (this.user && this.user.plan) {
        return this.user.plan?.pointPlan.price.toLocaleString()
      }
      return '-';
    },
    pointPriceNumber(): number {
      if (this.user && this.user.plan) {
        return this.user.plan.pointPlan.price;
      }
      return 0;
    }
  },
  methods: {
    async checkout() {
      if (this.newPoints) {
        commonModule.setLoader(true);
        const res = await ApiPoint.postPurchasePoint(this.newPoints);
        commonModule.setLoader(false);
        const stripeKey = process.env.VUE_APP_STRIPE_PK || '';
        const stripe = Stripe(stripeKey) as stripe.Stripe;
        if (res.sessionId && stripe) {
          stripe.redirectToCheckout({
            sessionId: res.sessionId,
          });
        }
      }
    }
  },
  async created() {
    commonModule.setLoader(true);
    const data = await ApiPoint.getPoint();
    this.currentPoints = data.point;
    commonModule.setLoader(false);

    const user = await ApiUser.getUser();
    if (user) {
      this.user = user;
    }
  }
})
