









































import Vue from 'vue'
import { authModule } from '@/store/AuthModule';
import { commonModule } from '@/store/CommonModule';

export default Vue.extend({
  name: 'SignIn',

  data: () => {
    return {
      email: '',
      password: '',
      showContent: false
    }
  },

  async created() {
    if (authModule.accessToken && authModule.refreshToken) {
      await authModule.checkAuth().then((res) => {
        if (res) {
          this.$router.push({name: 'Home'});
        } else {
          this.showContent = true;
        }
      })
    } else {
      this.showContent = true;
    }
  },

  methods: {
    async onSignIn(): Promise<void> {
      const input = {
        email: this.email,
        password: this.password
      };
      commonModule.setLoader(true);
      await authModule.authorize(input).then((res) => {
        if (res?.isPasswordResetRequired) {
          this.$router.push({ name: 'ChangePassword' });
        } else if (res?.isSubscribed) {
          this.$router.push({name: 'Home'});
        } else {
          this.$router.push({name: 'NoSubscription'});
        }
      }).catch((error) => {
        commonModule.setLoader(false);
        throw error;
      })
    }
  },

  computed: {
    isDisabled(): boolean {
      return !this.email || !this.password;
    }
  }
})
