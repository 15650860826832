/* eslint-disable @typescript-eslint/no-explicit-any */

import Swal from "sweetalert2";
import axios, { AxiosResponse, AxiosError } from "axios";
import { when } from "@/service/Switch";
import { authModule } from "@/store/AuthModule";

const url = process.env.VUE_APP_API_URL;
const xKey = process.env.VUE_APP_API_X_KEY;

const getHeaders = () => {
  try {
    if (authModule.hasAuthorized) {
      const token = authModule.accessToken;
      return {
        "Content-Type": "application/json; charset=utf-8",
        "x-api-key": xKey,
        Authorization: `Bearer ${token}`,
      };
    } else {
      throw Error("Not Authorized");
    }
  } catch (error) {
    // ログイン画面に戻す
    Swal.fire(
      "セッションが切れました",
      "最初からやり直してください",
      "error"
    ).then(() => {
      window.location.href = "/";
    });
    throw error;
  }
};

const getErrorMessage = (errorCode: string): string => {
  return when(errorCode)
    .on(
      (v) => v === "E000",
      () =>
        "システムに予期せぬエラーが発生しました。しばらく時間をおいてアクセス頂くかカスタマーサポートにお問い合わせください。"
    )
    .on(
      (v) => v === "E001",
      () => "API仕様書で指定されているのとパラメータが違います。"
    )
    .on(
      (v) => v === "E002",
      () => "セッション情報が不正です。"
    )
    .on(
      (v) => v === "E003",
      () => "セッションの有効期限が切れています"
    )
    .on(
      (v) => v === "E004",
      () => "アクセストークンが不正です。"
    )
    .on(
      (v) => v === "E005",
      () => "アクセストークンが失効しています。"
    )
    .on(
      (v) => v === "E006",
      () => "パスワードが違います。"
    )
    .on(
      (v) => v === "E007",
      () => "ユーザーが存在しません。"
    )
    .on(
      (v) => v === "E008",
      () => "ユーザーが既に存在しています。"
    )
    .on(
      (v) => v === "E009",
      () => "指定されたObjectTypeが不正です。"
    )
    .on(
      (v) => v === "E010",
      () => "内部的に使用されているIDが不正です。"
    )
    .on(
      (v) => v === "E011",
      () => "uqidが不正です。"
    )
    .on(
      (v) => v === "E012",
      () => "CheckSumが不正です。"
    )
    .on(
      (v) => v === "E013",
      () => "リレーションがありません。(バックエンド用)"
    )
    .on(
      (v) => v === "E014",
      () => "定数値の設定が見つかりません。"
    )
    .on(
      (v) => v === "E015",
      () => "サブスクリプションされていません。"
    )
    .on(
      (v) => v === "E016",
      () => "すでにサブスクリプションされています。"
    )
    .on(
      (v) => v === "E017",
      () => "日時の指定が不正です。"
    )
    .on(
      (v) => v === "E018",
      () => "最小利用時間より長い利用時間を指定してください。"
    )
    .on(
      (v) => v === "E019",
      () => "過去の時間を指定することはできません。"
    )
    .on(
      (v) => v === "E020",
      () => "指定された予約が存在しません。"
    )
    .on(
      (v) => v === "E021",
      () => "指定された予約はこのユーザーのものではありません"
    )
    .on(
      (v) => v === "E022",
      () => "この時間には予約できません。"
    )
    .on(
      (v) => v === "E023",
      () => "予約に必要なポイントが不足しています。"
    )
    .on(
      (v) => v === "E024",
      () => "終了状態の予約は削除できません。"
    )
    .on(
      (v) => v === "E025",
      () => "車の情報が存在しません。"
    )
    .on(
      (v) => v === "E026",
      () => "この車はこの物件のものではありません。"
    )
    .on(
      (v) => v === "E027",
      () => "このメールアドレスは既に使用されています。"
    )
    .on(
      (v) => v === "E028",
      () => "破損情報が存在しません。"
    )
    .on(
      (v) => v === "E029",
      () => "無効な署名です。"
    )
    .on(
      (v) => v === "E030",
      () => "プランを取得できませんでした。"
    )
    .on(
      (v) => v === "E031",
      () => "サブスクリプションの削除に失敗しました。"
    )
    .on(
      (v) => v === "E032",
      () => "顧客IDが存在しません。"
    )
    .on(
      (v) => v === "E033",
      () => "サブスクリプションIDが存在しません。"
    )
    .on(
      (v) => v === "E034",
      () => "チェックアウトセッション発行に失敗しました。"
    )
    .on(
      (v) => v === "E035",
      () => "支払い情報が既に存在します。"
    )
    .on(
      (v) => v === "E036",
      () =>
        "サブスクリプションが無効になっています。カード情報が有効か確認してください。"
    )
    .on(
      (v) => v === "E037",
      () => "初回パスワード変更が済んでいないためログインできません。"
    )
    .on(
      (v) => v === "E038",
      () => "メールアドレスの認証が済んでいないためログインできません。"
    )
    .on(
      (v) => v === "E039",
      () =>
        "?の形式が不正です。恐れ入りますが、ご確認のうえ再度実行してください。"
    )
    .on(
      (v) => v === "E040",
      () => "試行回数の上限を超えました。しばらくしてから再度お試しください。"
    )
    .on(
      (v) => v === "E041",
      () => "指定された時刻は予約終了時刻として指定できません。"
    )
    .on(
      (v) => v === "E042",
      () =>
        "指定された曜日は予約開始時刻および予約終了時刻として指定できません。"
    )
    .on(
      (v) => v === "E043",
      () =>
        "パスワードは数字及び大・小文字の英字を含み、8文字以上で設定してください。"
    )
    .otherwise(
      () =>
        "システムに予期せぬエラーが発生しました。しばらく時間をおいてアクセス頂くかカスタマーサポートにお問い合わせください。"
    );
};

export const fidoApi = (() => {
  const client = axios.create();

  client.interceptors.response.use(
    async (response) => {
      // Status Code <= 200
      const axiosRes = response as AxiosResponse;
      if (axiosRes.data.error) {
        const errorCode = String(axiosRes.data.error);
        const message = getErrorMessage(errorCode);
        Swal.fire(errorCode, `${message}`, "error");
        throw response;
      }
      return response;
    },
    async (error) => {
      // Status Code > 200
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        if (axiosError.response.status === 401) {
          // 403 forbidden
          const message = axiosError.response.data.message;
          if (message && message === "Unauthorized") {
            Swal.fire(
              "セッションが切れました",
              "最初からやり直してください",
              "error"
            ).then(() => {
              window.location.href = "/";
            });
            throw error;
          }
        } else if (axiosError.response.status === 403) {
          // 403 forbidden
          const message = axiosError.response.data.message;
          if (
            message &&
            message ===
              "User is not authorized to access this resource with an explicit deny"
          ) {
            Swal.fire(
              "トークンが不正です",
              "最初からやり直してください",
              "error"
            ).then(() => {
              window.location.href = "/";
            });
            throw error;
          }
        }

        const errorCode = String(axiosError.response.data.error);

        if (errorCode === "E005") {
          //  トークン失効
          Swal.fire(
            "セッションが切れました",
            "最初からやり直してください",
            "error"
          ).then(() => {
            window.location.href = "/";
          });
        } else {
          const message = getErrorMessage(errorCode);
          Swal.fire(errorCode, `${message}`, "error").then(() => {
            // if (errorCode === 'E043') return;
            window.location.href = "/";
          });
          throw error;
        }
      }

      throw error;
    }
  );

  return {
    get: async (endpoint: string, params: { [key: string]: any } | null) => {
      const response = await client.get(`${url}${endpoint}`, {
        params: params || null,
        headers: getHeaders(),
      });
      return response.data;
    },
    post: async (endpoint: string, params: { [key: string]: any } | null) => {
      const response = await client.post(
        `${url}${endpoint}`,
        params ? JSON.stringify(params) : null,
        { headers: getHeaders() }
      );
      return response.data;
    },
    postNoAuth: async (
      endpoint: string,
      params: { [key: string]: any } | null
    ) => {
      const response = await client.post(
        `${url}${endpoint}`,
        params ? JSON.stringify(params) : null,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "x-api-key": xKey,
          },
        }
      );
      return response.data;
    },
    putImage: async (url: string, file: File, contentType: string) => {
      const response = await client.put(url, file, {
        headers: { "Content-Type": contentType },
      });
      return response.data;
    },
    put: async (endpoint: string, params: { [key: string]: any } | null) => {
      const response = await client.put(
        `${url}${endpoint}`,
        params ? JSON.stringify(params) : null,
        { headers: getHeaders() }
      );
      return response.data;
    },
    delete: async (endpoint: string, params: { [key: string]: any } | null) => {
      const response = await client.delete(`${url}${endpoint}`, {
        params: params || null,
        headers: getHeaders(),
      });
      return response.data;
    },
  };
})();
