import {
  CreateReservationRequestBody,
  ReservationArrayResponse,
  ReservationResponse,
  CarArrayResponse,
  AvailableCarArrayResponse,
  EstimatedPointResponse,
  UpdateReservationRequestBody,
  PointResponse,
} from "fido-entity/lib";
import { fidoApi } from "../common";

export default class ApiReserve {
  public static async getReserveList(): Promise<ReservationArrayResponse> {
    const res = await fidoApi.get("/reserve", null);

    if (res) {
      return res as ReservationArrayResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async createReserve(
    input: CreateReservationRequestBody
  ): Promise<ReservationResponse> {
    const res = await fidoApi.post("/reserve", input);

    if (res) {
      return res as ReservationResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async getAvailableCars(input: {
    startedAt: string;
    endedAt: string;
  }): Promise<CarArrayResponse> {
    const res = await fidoApi.get("/reserve/car", input);

    if (res) {
      return res as CarArrayResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async getAvailableTimes(input: {
    startedAt: string;
    endedAt: string;
  }): Promise<AvailableCarArrayResponse> {
    const res = await fidoApi.get("/reserve/car/time", input);

    if (res) {
      return res as AvailableCarArrayResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async getConsumedPoint(input: {
    startedAt: string;
    endedAt: string;
  }): Promise<EstimatedPointResponse> {
    const res = await fidoApi.get("/reserve/point", input);

    if (res) {
      return res as EstimatedPointResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async getReturnedPoint(
    reservationId: string
  ): Promise<EstimatedPointResponse> {
    const res = await fidoApi.get("/reserve/point/cancel", { reservationId });

    if (res) {
      return res as EstimatedPointResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async deleteReserve(
    reservationId: string
  ): Promise<PointResponse> {
    const res = await fidoApi.delete(`/reserve/${reservationId}`, null);

    if (res) {
      return res as PointResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async getReserve(
    reservationId: string
  ): Promise<ReservationResponse> {
    const res = await fidoApi.get(`/reserve/${reservationId}`, null);

    if (res) {
      return res as ReservationResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async updateReserve(
    reservationId: string,
    input: UpdateReservationRequestBody
  ): Promise<ReservationResponse> {
    const res = await fidoApi.put(`/reserve/${reservationId}`, input);

    if (res) {
      return res as ReservationResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async finishReserve(
    reservationId: string
  ): Promise<ReservationResponse> {
    const res = await fidoApi.post(`/reserve/${reservationId}/finish`, null);

    if (res) {
      return res as ReservationResponse;
    }

    throw Error("Response is null / undefined");
  }
}
