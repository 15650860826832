















import dayjs from 'dayjs'
import Vue from 'vue'
export default Vue.extend({
  name: 'TimePickCell',

  props: {
    id: {
      type: Number,
      default: 0
    },
    date: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    range(): string {
      const date = dayjs(this.date);
      return date.format('YYYY/M/D H:00 ~ ') + `${date.hour() + 1}:00`
    },
    displayTitle(): boolean {
      return !dayjs(this.date).hour() || !this.id;
    },
    dateLabel(): string {
      return dayjs(this.date).format('YYYY年M月D日');
    },
    getFeatureClass(): string {
      let classname = '';

      if (this.highlight) {
        classname = 'highlight';
      } else if (this.disabled) {
        classname = 'disabled';
      }

      if (this.outlined) {
        classname += ' outlined';
      }

      return classname;
    }
  }
})
