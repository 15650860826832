


































import Vue from 'vue'
import PeriodExpresser from '@/components/PeriodExpresser.vue'
export default Vue.extend({
  components: { PeriodExpresser },
  name: 'ReservationComplete',

  props: {
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    leftPoint: {
      type: String,
      default: "0"
    }
  },

  created() {
    if (!this.startDate || !this.endDate ||!this.leftPoint) {
      this.$router.push({ name: 'Home' });
    }
  }
  
})
