



































import Vue from 'vue'
import PeriodExpresser from '@/components/PeriodExpresser.vue'
import ApiReservation from '@/api/methods/Reservation'
import { commonModule } from '@/store/CommonModule'

export default Vue.extend({
  components: { PeriodExpresser },
  name: 'CancelReservation',

  data: () => {
    return {
      startDate: '',
      endDate: '',
      backPoint: 0
    }
  },

  props:{
    reservationId: {
      type: String,
      default: ''
    }
  },

  async created() {
    if (!this.reservationId) {
      this.$router.push({name: 'Home'});
      return;
    }

    commonModule.setLoader(true);
    const data = await ApiReservation.getReserve(this.reservationId);
    if (data.reservation.startedAt && data.reservation.endedAt) {
      this.startDate = data.reservation.startedAt;
      this.endDate = data.reservation.endedAt;

      const bp = await ApiReservation.getReturnedPoint(data.reservation.id);
      if (bp.point) {
        this.backPoint = bp.point;
      }
    }
    commonModule.setLoader(false);
  },

  methods: {
    async onCancelButton() {
      commonModule.setLoader(true);
      await ApiReservation.deleteReserve(this.reservationId).then((res) => {
        this.$router.push({name: 'CancelComplete', params: {
          point: res.point.toString()
        }})
      })
      commonModule.setLoader(false);
    }
  },

  computed: {
    showContent(): boolean {
      return this.startDate !== '' && this.endDate !== '';
    }
  }
  
})
