import {
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface CommonState {
  isLoading: boolean;
}

@Module({
  dynamic: true,
  store,
  name: "CommonModule",
  namespaced: true,
})
class CommonModule extends VuexModule implements CommonState {
  public isLoading = false;

  @Mutation
  public setLoader(showLoader: boolean) {
    this.isLoading = showLoader;
  }
}

export const commonModule = getModule(CommonModule);
