




















import Vue from 'vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween);

export default Vue.extend({
  name: 'ProgressCircle',

  props: {
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 180
    }
  },

  data:() => {
    return {
      currentDate: dayjs(),
    };
  },

  mounted() {
    setInterval(this.updateData, 1000);
  },

  methods:{
    updateData() {
      this.currentDate = dayjs();
    }
  },

  computed: {
    remainTime(): string {
      if (!this.currentDate.isBetween(this.startDate, this.endDate)) {
        return '日付が不正です';
      }

      const minSum = dayjs(this.endDate).diff(this.currentDate, 'minute');
      const hour = Math.floor(minSum / 60);
      const min = minSum % 60;
      return (hour > 0 ? (hour + '時間') : '') + min + '分';
    },
    rate(): number {
      if (!this.currentDate.isBetween(this.startDate, this.endDate)) {
        return 0;
      }

      const all = dayjs(this.endDate).diff(this.startDate, 'minute');
      const remain = dayjs(this.endDate).diff(this.currentDate, 'minute');
      return remain / all;
    }
  }
})
