



























import Vue from 'vue'
import ProgressTracker from '@/components/ProgressTracker.vue'
import dayjs from 'dayjs';
export default Vue.extend({
  components: { ProgressTracker },
  name: 'DatePicker',

  data: () => {
    return {
      date: ''
    }
  },

  methods: {
    toPickTime(): void {
      this.$router.push({name: 'PickTime', params: {date: this.date}});
    },
    allowedDates(date: string) {
      return dayjs(date).isAfter(dayjs(), 'day') && dayjs(date).day() !== 2;
    } 
  },

  computed: {
    
  }
})
