
















import dayjs from 'dayjs'
import Vue from 'vue'
export default Vue.extend({
  name: 'PeriodExpresser',

  props: {
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    startDay(): string {
      return this.startDate ? dayjs(this.startDate).format('M/D') : '';
    },
    startTime(): string {
      return this.startDate ? dayjs(this.startDate).format('H:mm'): '';
    },
    endDay(): string {
      return this.endDate ? dayjs(this.endDate).format('M/D') : '';
    },
    endTime(): string {
      return this.endDate ? dayjs(this.endDate).format('H:mm') : '';
    },
    flexStyle():string {
      if (this.dense) {
        return 'flex-column align-start';
      } else {
        return 'flex-row align-end';
      }
    }
  }

})
