





















import Vue from 'vue';

import ApiPayment from '@/api/methods/Payment';
import { commonModule } from '@/store/CommonModule';
import { authModule } from '@/store/AuthModule';

export default Vue.extend({
  methods: {
    async gotoPotal() {
      commonModule.setLoader(true);
      const res = await ApiPayment.getPortalUrl();
      if (res && res.url) {
        window.open(res.url);
      }
      commonModule.setLoader(false);
    },
    logout() {
      authModule.unAuthorize();
      this.$router.push({name:'SignIn'});
    }
  }
})
