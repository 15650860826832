








































import Vue from 'vue'
import ApiUser from '@/api/methods/User';
import { commonModule } from '@/store/CommonModule'
import Swal from 'sweetalert2';

export default Vue.extend({
  name: 'ChangePassword',

  data: () => {
    return {
      currentPassword: '',
      newPassword: '',
      validation: ''
    }
  },

  methods: {
    async onClicked() {
      commonModule.setLoader(true);
      await ApiUser.updatePassword({
        previousPassword: this.currentPassword,
        proposedPassword: this.newPassword
      }).then(() => {
        this.currentPassword = '';
        this.newPassword = '';
        Swal.fire('変更完了', 'パスワードを変更しました。', 'success');
      })
      .finally(() => {
        commonModule.setLoader(false);
        // this.$router.push({name: 'Home'});
      });
    }
  },

  computed: {
    isInputSame(): boolean {
      return this.newPassword !== '' && this.newPassword === this.validation;
    }
  }
})
