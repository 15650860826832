import { PointResponse, PaymentCheckoutResponse } from "fido-entity/lib";
import { fidoApi } from "../common";

export default class ApiPoint {
  public static async getPoint(): Promise<PointResponse> {
    const res = await fidoApi.get("/point", null);

    if (res) {
      return res as PointResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async postPurchasePoint(point: number): Promise<PaymentCheckoutResponse> {
    const res = await fidoApi.post("/point/purchase", {
      purchasePoint: point,
    });

    if (res) {
      return res as PaymentCheckoutResponse;
    }

    throw Error("Response is null / undefined");
  }
}
