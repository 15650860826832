import {
  AuthSigninRequestBody,
  AuthSessionResponse,
  AuthTokenInfoRequestBody,
  AuthTokenResponse,
  CryptoUtils,
} from "fido-entity/lib";
import { fidoApi } from "../common";

const aesKey = process.env.VUE_APP_AES_KEY;
const aesSalt = process.env.VUE_APP_AES_SALT;

export default class ApiAuth {
  // 暗号化はここで行う
  public static async signIn(
    input: AuthSigninRequestBody
  ): Promise<AuthSessionResponse> {
    const cu = new CryptoUtils(aesKey, aesSalt);
    const encrypted = cu.encryptAes(input.password);
    const newInput: AuthSigninRequestBody = {
      email: input.email,
      password: encrypted,
    };

    const res = await fidoApi.postNoAuth("/signin", newInput);

    if (res) {
      return res as AuthSessionResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async checkAuth(
    input: AuthTokenInfoRequestBody
  ): Promise<AuthTokenResponse> {
    const res = await fidoApi.postNoAuth("/signin/tokeninfo", input);

    if (res) {
      return res as AuthTokenResponse;
    }

    throw Error("Response is null / undefined");
  }
}
