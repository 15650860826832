



















import Vue from 'vue'
export default Vue.extend({
  name: 'UploadComplete',

  data: () => {
    return {
      points: 0
    }
  }
  
})
