import {
  CustomerPortalResponse,
  PaymentCheckoutResponse,
} from "fido-entity/lib";
import { fidoApi } from "../common";

export default class ApiPayment {
  public static async getPortalUrl(): Promise<CustomerPortalResponse> {
    const res = await fidoApi.get("/payment/portal", null);

    if (res) {
      return res as CustomerPortalResponse;
    }

    throw Error("Response is null / undefined");
  }

  public static async postSubscription(): Promise<PaymentCheckoutResponse> {
    const res = await fidoApi.post("/payment/subscribe", null);

    if (res) {
      return res as PaymentCheckoutResponse;
    }

    throw Error("Response is null / undefined");
  }
}
