import Vue from "vue";
import Vuex from "vuex";
import { AuthState } from "@/store/AuthModule";
import { CommonState } from "@/store/CommonModule";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

export interface RootState {
  authState: AuthState;
  commonState: CommonState;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ["AuthModule"],
});

export default new Vuex.Store<RootState>({
  plugins: [vuexLocal.plugin],
});
