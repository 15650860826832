


















import Vue from 'vue'
export default Vue.extend({
  name: 'PointInfo',

  props: {
    points: {
      type: Number,
      default: 0
    }
  },

  methods: {
    onAddButton(): void {
      this.$router.push({name: 'BuyPoint'});
    }
  }
})
