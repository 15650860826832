



































import Vue from 'vue'
import ProgressTracker from '@/components/ProgressTracker.vue';
import PeriodExpresser from '@/components/PeriodExpresser.vue'
import ApiReserve from '@/api/methods/Reservation';
import { commonModule } from '@/store/CommonModule';
export default Vue.extend({
  components: { ProgressTracker, PeriodExpresser },
  name: 'ConfirmReservation',

  data: () => {
    return { 
      estimatedPoint: 0
    }
  },

  props: {
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    carId: {
      type: String,
      default: ''
    }
  },

  methods: {
    async createReservation() {
      const input = {
        startedAt: this.startDate,
        endedAt: this.endDate,
        carId: this.carId
      }
      commonModule.setLoader(true);
      await ApiReserve.createReserve(input).then((res) => {
        this.$router.push({name: 'ReservationComplete', params: {
          startDate: res.reservation.startedAt,
          endDate: res.reservation.endedAt,
          leftPoint: res.point.toString()
        }});
      })
      commonModule.setLoader(false);
    }
  },

  async created() {
    if (this.startDate && this.endDate && this.carId) {
      const input = {
        startedAt: this.startDate,
        endedAt: this.endDate
      };
      commonModule.setLoader(true);
      await ApiReserve.getConsumedPoint(input).then((res) => {
        this.estimatedPoint = res.point;
      }).catch(() => {
        this.$router.push({ name: 'PickDate' });
      })
      commonModule.setLoader(false);
    } else {
      this.$router.push({ name: 'PickDate' });
    }
  }
  
})
